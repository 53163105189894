import { getPaymentInformaion } from "logik/apiCalls";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";

import React, { Component } from "react";
import NewPaymentInformation from "./NewPaymentInformation";

interface Props {
    selectedPayment?: number;
    onPaymentSelect?: (payment: number) => void;
}
interface State {
    showNewZahlungsInformation: boolean;
    paymentInformations: PaymentInformation[];
}

export interface PaymentInformationInterface {
    kPayment: number;
    cZahlungsArt: string;
    cIban: string;
    cKontoInhaber: string;
    cBIC: string;
}

export default class PaymentInformation extends Component<Props, State> {
    state = {
        paymentInformations: [],
        showNewZahlungsInformation: false,
    };

    constructor(props: Props) {
        super(props);
        this.getPaymentInformation = this.getPaymentInformation.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        this.getPaymentInformation();
    }

    render() {
        return (
            <div>
                <h2>Vorhandene Adressen:</h2>
                <div>
                    {this.state.paymentInformations.length === 0 ? (
                        <strong>Keine Adressen vorhanden</strong>
                    ) : (
                        <ul>
                            {this.state.paymentInformations.map(
                                (payment: PaymentInformationInterface) => (
                                    <li key={payment.kPayment}>
                                        {this.props.onPaymentSelect && (
                                            <RadioButton
                                                name="payment"
                                                value={payment.kPayment}
                                                onChange={(e) => {
                                                    if (
                                                        this.props
                                                            .onPaymentSelect
                                                    ) {
                                                        this.props.onPaymentSelect(
                                                            e.value
                                                        );
                                                    }
                                                }}
                                                checked={
                                                    this.props
                                                        .selectedPayment ===
                                                    payment.kPayment
                                                }
                                            />
                                        )}
                                        {payment.cZahlungsArt === "sepa" ? (
                                            <>
                                                <strong>SEPA</strong>
                                                <br />

                                                {payment.cKontoInhaber}
                                                <br />
                                                {payment.cIban}
                                                <br />
                                                {payment.cBIC}
                                            </>
                                        ) : (
                                            <strong>Auf Rechnung</strong>
                                        )}
                                    </li>
                                )
                            )}
                        </ul>
                    )}
                </div>
                <Button
                    label="Zahlungsart hinzufügen"
                    className="mr-2"
                    onClick={() =>
                        this.setState({ showNewZahlungsInformation: true })
                    }
                />
                <Button
                    label="Auf Rechnung zahlen hinzufügen"
                    onClick={() =>
                        this.setState({ showNewZahlungsInformation: true })
                    }
                />
                <Dialog
                    style={{ width: "90vw" }}
                    onHide={() =>
                        this.setState({ showNewZahlungsInformation: false })
                    }
                    visible={this.state.showNewZahlungsInformation}
                    header="Neue Zahlungsart hinzufügen"
                >
                    <NewPaymentInformation onHide={this.onHide} />
                </Dialog>
            </div>
        );
    }

    onHide() {
        this.setState({ showNewZahlungsInformation: false });
        this.getPaymentInformation();
    }

    async getPaymentInformation() {
        var paymentInformations: PaymentInformation[] =
            await getPaymentInformaion();
        console.log(paymentInformations);
        this.setState({ paymentInformations: paymentInformations });
    }
}
