import React, { Component } from "react";
import "./layout.css";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Link, NavLink } from "react-router-dom";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { UserContext } from "App";
import { Button } from "primereact/button";
import ThemeSwitcher from "components/account/ThemeSwitcher";
import RightChecker from "components/shared/RightChecker";
import NotificationDropdown from "components/notification/NotificationDropdown";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
export default class Navbar extends Component {
    constructor(props) {
        super(props);

        this.notRef = React.createRef();
        this.addNotification = this.addNotification.bind(this);
    }

    render() {
        return (
            <nav className="bg-white pr-3 pl-3 ml-3 mr-3 mt-3 mb-3 static-top p-shadow-2 flex p-p-2 align-center">
                <HiOutlineMenuAlt1 onClick={this.props.toggleNavHandler} />
                <ul className="p-navbar ml-auto align-center flex">
                    <li className="mr-4">
                        <NotificationDropdown
                            ref={(el) => (this.notRef = el)}
                        />
                    </li>
                    <li
                        className="nav-item dropdown no-arrow"
                        onClick={(e) => this.op.toggle(e)}
                    >
                        <UserContext.Consumer>
                            {(value) =>
                                value.username !== undefined && (
                                    <span
                                        id="userDropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span className="mr-2 p-d-none d-lg-inline text-gray-600 small">
                                            {value.username}
                                        </span>
                                        <Avatar
                                            label={
                                                value.username[0] +
                                                value.username[1]
                                            }
                                            shape="circle"
                                            className="ml-1 pointer"
                                            size="large"
                                        />

                                        <OverlayPanel
                                            ref={(el) => (this.op = el)}
                                            id="overlay_panel"
                                        >
                                            <NavLink
                                                to="/account"
                                                className="p-button-rounded"
                                            >
                                                <Button label="Account" />{" "}
                                            </NavLink>
                                            <br />
                                            <i className="pi pi-arrow-circle-left"></i>

                                            <Link
                                                to="/login"
                                                className="p-button-rounded text-danger"
                                                onClick={() => {
                                                    firebase.auth().signOut();

                                                }}
                                            >
                                                Logout
                                            </Link>
                                            <RightChecker right="10000">
                                                <br />
                                                <br />
                                            </RightChecker>
                                            <ThemeSwitcher />
                                        </OverlayPanel>
                                    </span>
                                )
                            }
                        </UserContext.Consumer>
                    </li>
                </ul>
            </nav>
        );
    }

    addNotification(notification) {
        this.notRef.addNotification(notification);
    }
}
