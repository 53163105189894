import React, { Component, Suspense } from "react";
import Layout from "./components/layout/Layout";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { ProtectedRoute } from "./components/shared/ProtectedRoute";
import axios from "axios";
import { AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import { rightsGetUserSettings } from "logik/rightApiCalls";
import { ProgressSpinner } from "primereact/progressspinner";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { DateTime } from "luxon";
import LoginContainer from "components/login/LoginContainer";
import RegisterDomain from "./components/domain/RegisterDomain";
import ActivateMFA from "components/login/ActivateMFA";
import PaymentOverview from "components/payment/PaymentOverview";
import AddUser from "components/administration/manuelleEinpflegung/AddUser";
import AddDomain from "components/administration/manuelleEinpflegung/AddDomain";
import ShowAbo from "components/administration/manuelleEinpflegung/ShowAbo";
import AddTariff from "components/administration/manuelleEinpflegung/AddTariff";
import ShowCertificates from "components/administration/manuelleEinpflegung/ShowCertificates";
import AddCertificate from "components/administration/manuelleEinpflegung/AddCertificate";
import ShowUserTariff from "components/administration/manuelleEinpflegung/ShowUserTariff";
import FullUserData from "components/administration/manuelleEinpflegung/FullUserData";
import { Button } from "primereact/button";
import { nodeAxios, testNodeAxios } from "logik/nodeApiCalls";

const BuyDomainFromShop = React.lazy(() =>
    import("components/domain/BuyDomainFromShop")
);

const KampListe = React.lazy(() =>
    import("components/administration/kamp/KampListe")
);
const ServerDetailContainer = React.lazy(() =>
    import("components/server/server/ServerDetailContainer")
);
const AccountContainer = React.lazy(() =>
    import("components/account/AccountContainer")
);

const TarifManagement = React.lazy(() =>
    import("components/server/TarifMangagement/TarifManagement")
);

const FourZeroFourPage = React.lazy(() =>
    import("components/other/FourZeroFourPage")
);
const CostsOveriew = React.lazy(() =>
    import("components/server/CostsOverview/CostsOveriew")
);
const PoolContainer = React.lazy(() =>
    import("components/server/serverdashboard/PoolContainer")
);
const PoolsRightMangagement = React.lazy(() =>
    import("components/rightManagement/PoolsRightMangagement")
);
const ShoppingListContainer = React.lazy(() =>
    import("components/administration/shoppinglist/ShoppingListContainer")
);
const Registrate = React.lazy(() => import("./components/login/Registrate"));
const RenewPassword = React.lazy(() =>
    import("./components/login/RenewPassword")
);
const RequestNewPw = React.lazy(() =>
    import("./components/login/RequestNewPW")
);
const CreateNewVM = React.lazy(() =>
    import("./components/server/createnewserver/CreateNewVmContainer")
);

export const UserContext = React.createContext({
    isReseller: "0",
    rights: [],
    username: "",
    addNotification: () => null,
    user: null,
    refreshUser: () => null,
});

class App extends Component {
    constructor(props) {
        super(props);

        axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_DOMAIN;
        PrimeReact.ripple = true;
        // locale('de');
        this.state = {
            loading: true,
            isAuthenticated: false,
            userSettings: { isReseller: 0, rights: [], user: null },
            username: "",
            addNotification: this.addNotification,
        };
        this.notRef = React.createRef();
        this.addNotification = this.addNotification.bind(this);
    }

    componentDidMount() {
        try {
            firebase.auth().onAuthStateChanged(async (user) => {
                console.log("user auth changed", user);
                if (user) {
                    axios.defaults.headers = {
                        Auth: await user
                            .getIdToken()
                            .catch((e) => console.log(e)),
                    };

                    nodeAxios.defaults.headers = {
                        Authorization: await user
                            .getIdToken()
                            .catch((e) => console.log(e)),
                    };

                    var userSettings = await rightsGetUserSettings();
                    console.log("userSettings", userSettings);
                    this.setState({
                        loading: false,
                        isAuthenticated: true,
                        userSettings: {
                            ...userSettings,
                            addNotification: this.addNotification,
                            user: user,
                        },
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false,
                        isAuthenticated: false,
                        userSettings: { isReseller: 0, rights: [], user: null },
                        username: "",

                        addNotification: this.addNotification,
                    });
                    axios.defaults.headers = {
                        Auth: "",
                    };
                    nodeAxios.defaults.headers = {
                        Authorization: "",
                    };
                }
            });
        } catch (err) {
            console.warn(err);
        }
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        return (
            <>
                {!this.state.loading && (
                    <UserContext.Provider
                        value={{
                            ...this.state.userSettings,
                            refreshUser: this.refreshUser,
                        }}
                    >
                        <Switch>
                            <Route path="/login">
                                <LoginContainer redirect={true}>
                                    <Login
                                        onLogin={this.onLogin}
                                        redirect={true}
                                    />
                                </LoginContainer>
                            </Route>
                            <Route path="/activateMFA">
                                <LoginContainer redirect={true}>
                                    <ActivateMFA />
                                </LoginContainer>
                            </Route>
                            <Route path="/buyDomain">
                                <Suspense fallback={<div>loading...</div>}>
                                    <BuyDomainFromShop />
                                </Suspense>
                            </Route>
                            <Route path="/registration">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <LoginContainer redirect={true}>
                                        <Registrate />
                                    </LoginContainer>
                                </Suspense>
                            </Route>
                            <Route path="/newpassword">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <LoginContainer redirect={true}>
                                        <RenewPassword />
                                    </LoginContainer>
                                </Suspense>
                            </Route>
                            <Route path="/requestNewPw">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <LoginContainer redirect={true}>
                                        <RequestNewPw />
                                    </LoginContainer>
                                </Suspense>
                            </Route>
                            <Layout ref={(el) => (this.notRef = el)}>
                                <AnimatePresence>
                                    <Suspense fallback={<ProgressSpinner />}>
                                        <Switch
                                            location={this.props.location}
                                            key={this.props.location.pathname}
                                        >
                                            <ProtectedRoute exact path="/">
                                                <Redirect to="/dashboard" />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/server/:id">
                                                <ServerDetailContainer />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/server">
                                                <PoolContainer />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/dashboard">
                                                <Dashboard />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/newserver">
                                                <CreateNewVM />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/chronic">
                                                <CostsOveriew />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/registerDomain">
                                                <RegisterDomain />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/rightmanagement">
                                                <PoolsRightMangagement />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/bezahlinformationen">
                                                <PaymentOverview />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/shoppinglist">
                                                <ShoppingListContainer />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/kamp">
                                                <KampListe />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/account">
                                                <AccountContainer />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/adminTarife">
                                                <TarifManagement />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/adminAddUser">
                                                <AddUser />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/adminAddDomain">
                                                <AddDomain />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/AddTariff">
                                                <AddTariff />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/ShowAbo">
                                                <ShowAbo />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/ShowCertificates">
                                                <ShowCertificates />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/AddCertificate">
                                                <AddCertificate />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/ShowUserTariff">
                                                <ShowUserTariff />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="/FullUserData">
                                                <FullUserData />
                                            </ProtectedRoute>
                                            <ProtectedRoute path="*">
                                                <FourZeroFourPage />
                                            </ProtectedRoute>
                                        </Switch>
                                    </Suspense>
                                </AnimatePresence>
                            </Layout>
                        </Switch>
                    </UserContext.Provider>
                )}
            </>
        );
    }

    onLogin = async () => {
        const user = firebase.auth().currentUser;
        axios.defaults.headers = {
            Auth: await user.getIdToken(),
        };
        nodeAxios.defaults.headers = {
            Authorization: "",
        };
        var userSettings = await rightsGetUserSettings();

        this.setState({
            loading: false,
            isAuthenticated: true,
            userSettings: {
                ...userSettings,
                addNotification: this.addNotification,
                user: user,
            },
        });
        this.props.history.push("/");
    };

    addNotification(
        detail,
        severity = "info",
        life = 90000,
        closable = true,
        content = null,
        summary = null
    ) {
        this.notRef.addNotification({
            detail: detail,
            severity: severity,
            life: life,
            closable: closable,
            content: content,
            summary: summary,
            time: DateTime.now(),
        });
    }

    refreshUser = async () => {
        var user = firebase.auth().currentUser;
        let newUserSettings = {
            userSettings: {
                ...this.state.userSettings,
                addNotification: this.addNotification,
                user: user,
            },
        };
        await this.setState(newUserSettings, () => {
            console.log(this.state.userSettings);
        });
    };
}

export default withRouter(App);
