import React, { Component } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Badge } from "primereact/badge";
import { ScrollPanel } from "primereact/scrollpanel";
import { Messages } from "primereact/messages";
import { DateTime } from "luxon";
import { Button } from "primereact/button";
export default class NotificationDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            startTime: DateTime.local().plus({ hours: -5 }),
        };
        this.onShow = this.onShow.bind(this);
        this.showNotifications = this.showNotifications.bind(this);
        this.getNotificatons = this.getNotificatons.bind(this);
        this.addNotification = this.addNotification.bind(this);
        this.older = this.older.bind(this);
    }

    componentDidMount() {
        this.getNotificatons();
    }

    render() {
        return (
            <>
                <i
                    className="pi pi-bell p-text-secondary p-overlay-badge"
                    style={{ fontSize: "1.5rem" }}
                    onClick={this.showNotifications}
                >
                    <Badge
                        value={
                            this.state.notifications.filter(
                                (e) => e.time > this.state.startTime.toSeconds()
                            ).length
                        }
                    ></Badge>
                </i>
                <OverlayPanel ref={(el) => (this.op = el)} onShow={this.onShow}>
                    <ScrollPanel style={{ height: "450px", width: "600px" }}>
                        <Messages ref={(el) => (this.msgs = el)} />
                        <Button
                            label={
                                "Ältere anzeigen (seit " +
                                this.state.startTime
                                    .plus({ days: -1 })
                                    .setLocale("de")
                                    .toLocaleString(DateTime.DATETIME_SHORT) +
                                ")"
                            }
                            onClick={this.older}
                        />
                    </ScrollPanel>
                </OverlayPanel>
            </>
        );
    }

    showNotifications(e) {
        this.op.toggle(e);
    }

    older(e) {
        e.stopPropagation();
        e.preventDefault();
        console.log("hallo");
        this.setState(
            { startTime: this.state.startTime.plus({ days: -1 }) },
            this.onShow
        );
    }

    async getNotificatons() {
        //  var nots = await proxGetNotifications();
        //    this.setState({ notifications: nots });
    }

    onShow() {
        console.table(this.state.notifications);
        console.table(
            this.state.notifications.filter(
                (e) => e.time > this.state.startTime
            )
        );
        this.msgs.show(
            this.state.notifications
                .filter((e) => e.time > this.state.startTime.toSeconds())
                .map((e) => {
                    return {
                        severity: "info",
                        summary: "",
                        content: e.detail,
                        sticky: true,
                    };
                })
        );
    }

    addNotification(notification) {
        this.setState({
            notifications: [...this.state.notifications, notification],
        });
    }
}
