import { adminGetUser } from "logik/apiCalls";
import { AutoComplete } from "primereact/autocomplete";
import React, { Component } from "react";

type Props = {
  onSelect: (user: string) => void;
};

type State = {
  user: string[];
  currentUser: string;
  filteredUser: string[];
};

export default class UserAutocomplete extends Component<Props, State> {
  state = {
    user: [],
    currentUser: "",
    filteredUser: [],
  };

  constructor(props: Props) {
    super(props);
    this.getUser = this.getUser.bind(this);
    this.searchUser = this.searchUser.bind(this);
  }

  componentDidMount() {
    this.getUser();
  }

  render() {
    return (
      <div>
        <AutoComplete
          value={this.state.currentUser}
          suggestions={this.state.filteredUser}
          completeMethod={this.searchUser}
          forceSelection
          onChange={(e) => this.setState({ currentUser: e.value })}
          onSelect={(e) => this.props.onSelect(e.value)}
          dropdown
        />
      </div>
    );
  }

  async getUser() {
    var user = await adminGetUser();
    let userArray = user.map((e: any) => e.cFrontendName);
    this.setState({ user: userArray });
  }

  searchUser(event: any) {
    let filteredUsers;
    if (!event.query.trim().length) {
      filteredUsers = [...this.state.user];
    } else {
      filteredUsers = this.state.user.filter((currentUser: string) => {
        return currentUser.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    this.setState({ filteredUser: filteredUsers });
  }
}
