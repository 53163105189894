import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import { AutoComplete } from "primereact/autocomplete";
import {
  adminGetUser,
  giveUserAbo,
  giveUserCert,
  giveUserTarif,
} from "logik/apiCalls";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteButtonGlobal from "./DeleteButtonGlobal";
// import DeleteButtonGlobal from "./DeleteButtonGlobal";
// import PoolGrouper from "./PoolGrouper";
//
type Props = {};

const FullUserData = (props: Props) => {
  const [value, setValue]: any = useState("");
  const [users, setUsers] = useState([""]);
  const [listedUser, setListedUser] = useState("User-Übersicht");
  const [selectedUser, setselectedUser] = useState("");
  const [selectedObjectUser, setselectedObjectUser] = useState({});
  const [filteredUser, setfilteredUser] = useState(["string"]);
  const [certs, setCerts] = useState([[]]);
  const [tarifs, setTarifs] = useState([[]]);
  const [abos, setAbos] = useState([[]]);

  const searchUser = (event: any) => {
    setTimeout(() => {
      let _filteredUsers: string[];
      if (!event.query.trim().length) {
        _filteredUsers = [...users];
      } else {
        _filteredUsers = users.filter((user: any) => {
          return user.cFrontendName
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setfilteredUser(_filteredUsers);
    }, 250);
  };

  useEffect(() => {
    adminGetUser().then((data) => setUsers(data));
    setselectedObjectUser({ cFrontendName: "" });
  }, []);

  useEffect(() => {
    update();
  }, [selectedUser]);

  const update = async () => {
    setAbos(await giveUserAbo(selectedUser));
    setCerts(await giveUserCert(selectedUser));
    setTarifs(await giveUserTarif(selectedUser));
  };

  return (
    <div className="ml-5 mr-6">
      <div>
        <AutoComplete
          value={selectedObjectUser}
          suggestions={filteredUser}
          completeMethod={searchUser}
          placeholder="Wählen sie einen User"
          dropdown
          field="cFrontendName"
          onChange={(e) => {
            setselectedObjectUser(e.value);
            if (typeof e.value === "object" && e.value !== null) {
              setselectedUser(e.value.cFrontendName);
            }
          }}
          aria-label="Wähle einen User"
        />

        {
          <DeleteButtonGlobal
            certs={certs}
            tarifs={tarifs}
            abos={abos}
          ></DeleteButtonGlobal>
        }
      </div>
      <br></br>
      <Panel header={listedUser}>
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-12">
            <Fieldset legend="Domains" toggleable>
              <h1>Abos </h1>
              <DataTable
                value={abos}
                paginator
                rows={5}
                responsiveLayout="scroll"
              >
                <Column field="cDescription" header="cDescription"></Column>
                <Column field="cType" header="cType"></Column>
                <Column field="dAboBeendetAm" header="dAboBeendetAm"></Column>
                <Column field="dErstellt" header="dErstellt"></Column>
                <Column
                  field="fPreisProAbrechnugszeitraum"
                  header="fPreisProAbrechnugszeitraum"
                ></Column>
                <Column field="kcPoolName" header="kcPoolName"></Column>
                <Column
                  field="nAbrechnungszeitraumInTagen"
                  header="nAbrechnungszeitraumInTagen"
                ></Column>
              </DataTable>
            </Fieldset>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-6">
            <Fieldset legend="Tarife" toggleable>
              <h1>Tarife </h1>
              <DataTable
                value={tarifs}
                paginator
                rows={5}
                responsiveLayout="scroll"
              >
                <Column field="cNutzer" header="cNutzer"></Column>
                <Column field="nName" header="nName"></Column>
                <Column field="cDate" header="cDate"></Column>
                <Column field="cTariff" header="cTariff"></Column>
                <Column field="fPreis" header="fPreis"></Column>
              </DataTable>
            </Fieldset>
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <Fieldset legend="Zertifikate" toggleable>
              <h1>Zertifikate </h1>
              <DataTable
                value={certs}
                paginator
                rows={5}
                responsiveLayout="scroll"
              >
                <Column field="Domain" header="Domain"></Column>
                <Column field="cName" header="cName"></Column>
                <Column field="nID" header="nID"></Column>
              </DataTable>
            </Fieldset>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default FullUserData;
