import { giveUserAbo } from "logik/apiCalls";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import React, { Component } from "react";
import DeleteButton from "./DeleteButton";
import DeleteButtonCerts from "./DeleteButtonCerts";
import DeleteButtonTar from "./DeleteButtonTar";
import { AboInterface } from "./ShowAbo";
import { CertInterface } from "./ShowCertificates";
import { TarifInterface } from "./ShowUserTariff";

type Props = {
  abos: never[][];
  tarifs: never[][];
  certs: never[][];
};

type State = {
  displayBasic: boolean;
};

export default class DeleteButtonGlobal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayBasic: false,
    };
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    console.log(this.props.abos);
    console.log(this.props.certs);
    console.log(this.props.tarifs);
  }

  render() {
    return (
      <>
        <Button
          className="deleteButtonGlobal"
          label="Kündigunsinterface"
          aria-label="Kündigunsinterface"
          onClick={() => this.onClick("displayBasic")}
        />
        <Dialog
          header="Wähle die zu kündigenden Einträge"
          visible={this.state.displayBasic}
          style={{ width: "80vw" }}
          onHide={() => this.onHide("displayBasic")}
        >
          <DataTable
            value={this.props.abos}
            responsiveLayout="scroll"
            paginator
            rows={5}
          >
            <Column field="cDescription" header="cDescription"></Column>
            <Column field="cType" header="cType"></Column>
            <Column field="dErstellt" header="dErstellt"></Column>
            <Column
              body={(rowdata: AboInterface) => (
                <DeleteButton abo={rowdata}></DeleteButton>
              )}
            ></Column>
          </DataTable>
          <DataTable
            value={this.props.tarifs}
            responsiveLayout="scroll"
            paginator
            rows={5}
          >
            <Column field="nName" header="nName"></Column>
            <Column field="cTariff" header="cTariff"></Column>
            <Column field="fPreis" header="fPreis"></Column>
            <Column
              body={(rowdata: TarifInterface) => (
                <DeleteButtonTar tar={rowdata}></DeleteButtonTar>
              )}
            ></Column>
          </DataTable>
          <DataTable
            value={this.props.certs}
            responsiveLayout="scroll"
            paginator
            rows={5}
          >
            <Column field="Domain" header="Domain"></Column>
            <Column field="cName" header="cName"></Column>
            <Column field="nID" header="nID"></Column>
            <Column
              body={(rowdata: CertInterface) => (
                <DeleteButtonCerts cert={rowdata}></DeleteButtonCerts>
              )}
            ></Column>
          </DataTable>
        </Dialog>
      </>
    );
  }

  onClick(name: any) {
    this.setState({
      displayBasic: true,
    });
  }

  onHide(name: string) {
    this.setState({
      displayBasic: false,
    });
  }

  renderFooter(name: any) {
    return (
      <div>
        <Button
          label="Nein"
          icon="pi pi-times"
          onClick={() => this.onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          onClick={() => {
            // this.onHide(name),
            //   this.kuendige(
            //     this.props.abo.cDescription,
            //     this.props.abo.dErstellt,
            //     this.props.abo.kcPoolName
            //   );
          }}
          autoFocus
        />
      </div>
    );
  }
}
