import { savePaymentInformation } from "logik/apiCalls";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";

interface Props {
    onHide: () => void;
}
interface State {
    cZahlungsArt: string;
    cIban: string;
    cKontoInhaber: string;
    cBIC: string;
}

export default class NewPaymentInformation extends Component<Props, State> {
    state = {
        cZahlungsArt: "sepa",
        cIban: "",
        cKontoInhaber: "",
        cBIC: "",
    };

    constructor(props: Props) {
        super(props);

        this.saveInvoiceAdress = this.saveInvoiceAdress.bind(this);
    }

    render() {
        return (
            <div className="formgrid grid">
                <div className="field col-12">
                    <label htmlFor="address">Konto Inhaber</label>
                    <InputText
                        className=" w-full"
                        value={this.state.cKontoInhaber}
                        onChange={(e) =>
                            this.setState({
                                cKontoInhaber: e.target.value,
                            })
                        }
                        type="text"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="firstname6">IBAN</label>
                    <InputText
                        className=" w-full"
                        value={this.state.cIban}
                        onChange={(e) =>
                            this.setState({
                                cIban: e.target.value,
                            })
                        }
                        id="firstname6"
                        type="text"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="lastname6">BIC</label>
                    <InputText
                        className=" w-full"
                        value={this.state.cBIC}
                        onChange={(e) =>
                            this.setState({
                                cBIC: e.target.value,
                            })
                        }
                        id="lastname6"
                        type="text"
                    />
                </div>

                <Button label="Speichern" onClick={this.saveInvoiceAdress} />
            </div>
        );
    }

    saveInvoiceAdress = async () => {
        savePaymentInformation(
            this.state.cZahlungsArt,
            this.state.cIban,
            this.state.cKontoInhaber,
            this.state.cBIC
        );
        this.props.onHide();
    };
}
