import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  adminPoolList,
  adminShowCert,
  adminShowTariff,
  getCertList,
} from "logik/apiCalls";
import { Tag } from "primereact/tag";
import { MultiSelect } from "primereact/multiselect";
import CertificateCounter from "./CertificateCounter";
import CertificateVisual from "./CertificateVisual";
import YearlyCertificate from "./YearlyCertificate";
import { InputText } from "primereact/inputtext";
import { DateTime } from "luxon";
import { Button } from "primereact/button";
import DeleteButton from "./DeleteButton";
import DeleteButtonTar from "./DeleteButtonTar";
//@ts-ignore

export interface TarifInterface {
  cNutzer: string;
  nName: number;
  fPreis: string;
  cTariff: string;
  cDate: Date;
  KDN: string;
  cFirma: string;
}

export interface PoolInterface {
  kcPoolName: string;
}

type Props = {};

type State = {
  tariffs: TarifInterface[];
  pools: PoolInterface[];
  time: any;
  // globalFilterValue1: string;
  //   filters1: null,
};

export default class ShowDomains extends Component<Props, State> {
  state = {
    tariffs: [],
    pools: [{ kcPoolName: "moso" }],
    time: DateTime.now().plus({ days: -30 }),
    // globalFilterValue1: "",
    // filters1: null
  };

  constructor(props: Props) {
    super(props);

    this.showTariffList = this.showTariffList.bind(this);
    this.poolList = this.poolList.bind(this);
  }

  componentDidMount() {
    this.showTariffList();
  }

  // onGlobalFilterChange1(e: []) {
  //   const value = e.target.value;
  //   let filters1 = {this.state.filters1};
  //   filters1["global"].value = value;

  //   this.setState({ filters1, globalFilterValue1: value });
  // }

  render() {
    return (
      <div>
        <div className="grid ml-5 mr-6">
          <div className="col-12 md:col-6 lg:col-4">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <CertificateCounter />
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">24 new </span>
              <span className="text-500">since last visit</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <YearlyCertificate />
                <div
                  className="flex align-items-center justify-content-center bg-orange-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">%52+ </span>
              <span className="text-500">since last week</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <CertificateVisual />
                <div
                  className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-inbox text-cyan-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">520 </span>
              <span className="text-500">newly registered</span>
            </div>
          </div>
        </div>
        <div className="card">
          {/* <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={this.state.globalFilterValue1}
              onChange={this.onGlobalFilterChange1}
              placeholder="Keyword Search"
            />
          </span> */}
          <div className="ml-5 mr-6">
            <DataTable
              value={this.state.tariffs}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              filterDisplay="row"
              sortField="cNutzer"
              sortOrder={1}
            >
              <Column
                field="nName"
                header="VM-Name"
                sortable
                showFilterMatchModes={true}
                filter
              ></Column>
              <Column
                field=""
                header="VM-ID"
                sortable
                showFilterMatchModes={true}
                filter
              ></Column>
              <Column
                field="dErstellt"
                header="Erstellt am"
                sortable
                filter
                body={(rowdata: TarifInterface) => (
                  <Tag
                    severity="info"
                    value={
                      rowdata.cDate.toString().substring(8, 10) +
                      "/" +
                      rowdata.cDate.toString().substring(5, 7) +
                      "/" +
                      rowdata.cDate.toString().substring(0, 4)
                    }
                  ></Tag>
                )}
              ></Column>
              <Column
                field="cTariff"
                header="Tarif"
                sortable
                showFilterMatchModes={true}
                filter
              ></Column>
              <Column
                field="fPreis"
                header="Preis"
                sortable
                filter
                body={(rowdata: TarifInterface) => (
                  <Tag value={rowdata.fPreis + " €"}></Tag>
                )}
              ></Column>
              <Column field="cNutzer" header="Pool" sortable filter></Column>
              <Column>
                field="KDN" header="KDN" sortable filter body=
                {(rowdata: TarifInterface) => (
                  <Button
                    className="p-button-text"
                    label={"" + rowdata.KDN}
                    tooltip={rowdata.cFirma}
                  ></Button>
                )}
              </Column>{" "}
              <Column
                body={(rowdata: TarifInterface) => (
                  <DeleteButtonTar tar={rowdata}></DeleteButtonTar>
                )}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    );
  }

  async poolList() {
    let pools = await adminPoolList();
    this.setState({ pools: pools });
  }

  async showTariffList() {
    let tariffs = await adminShowTariff();
    this.setState({ tariffs: tariffs });
    // console.log(abos);
  }
}
