import { Dropdown } from 'primereact/dropdown'
import React, { Component } from 'react'

export default class ThemeSwitcher extends Component {

    themes = [
        {label: "Bootstrab Blau", value: "bootstrap4-light-blue" },
        {label: "Bootstrab Lila", value: "bootstrap4-light-purple" },
        {label: "Bootstrab Dark Blau", value: "bootstrap4-dark-blue" },
        {label: "Bootstrab Dark Lila", value: "bootstrap4-dark-purple" },
        {label: "Saga Blau", value: "saga-blue" },
        {label: "Saga Grün", value: "saga-green" },
        {label: "Saga Orange", value: "saga-orange" },
        {label: "Saga Lila", value: "saga-purple" },
        {label: "Vela Dark Blau", value: "vela-blue" },
        {label: "Vela Dark Grün", value: "vela-green" },
        {label: "Vela Dark Orange", value: "vela-orange" },
        {label: "Vela Dark Lila", value: "vela-purple" },
        {label: "rhea", value: "rhea" },
        {label: "nova", value: "nova" },
        {label: "Blau Eckig", value: "fluent-light" },
    ]

    constructor(props) {
        super(props)
    
        this.state = {
             theme: null
        }
    }

    render() {
        return (
            <div onClick={(e)=> e.stopPropagation()}>
            <Dropdown style={{width: "300px"}} value={this.state.theme} options={this.themes} onChange={(e) => this.changeTheme(e.value)} placeholder="Wähle ein Theme aus"/>

            </div>
        )
    } 

    changeTheme (theme) {
        this.setState({theme:theme})
        let themeLink = document.getElementById('app-theme');
        if (themeLink) {
          themeLink.href =  "/themes/"+theme+"/theme.css";
        }
      }
}
