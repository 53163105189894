import React, { Component } from "react";
import Sidemenu from "./Sidemenu";
import Navbar from "./Navbar";
import "./layout.css";
import FeedbackContainer from "./Feedback/FeedbackContainer";

export default class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navopen: true,
            isDesktop: true,
            innerWidth: 0,
        };
        this.toggleMobileNav = this.toggleMobileNav.bind(this);
        this.updatePredicate = this.updatePredicate.bind(this);
        this.notRef = React.createRef();
        this.addNotification = this.addNotification.bind(this);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ innerWidth: window.innerWidth });
        window.innerWidth < 768
            ? this.setState({ isDesktop: false, navopen: false })
            : this.setState({ isDesktop: true, navopen: true });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }



    render() {
        return (
            <div id="wrapper" className="flex">
                <Sidemenu
                    navopen={this.state.navopen}
                    isDesktop={this.state.isDesktop}
                    toggleNavHandler={this.toggleMobileNav}
                    innerWidth={this.state.innerWidth}
                />
                <div
                    id="content-wrapper"
                    className="flex flex-column w-100"
                >
                    <Navbar
                        ref={(el) => (this.notRef = el)}
                        toggleNavHandler={this.toggleMobileNav}
                        isDesktop={this.state.isDesktop}
                    />
                    <FeedbackContainer />
                    {this.props.children}
                </div>
            </div>
        );
    }

    toggleMobileNav() {
        this.setState({ navopen: !this.state.navopen });
    }
    addNotification(notification) {
        this.notRef.addNotification(notification);
    }
}
