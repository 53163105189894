import { adminGetCertCount } from "logik/apiCalls";
import React, { Component } from "react";

type Props = {};

type State = {
  domains: number;
};

export default class CertificateCounter extends Component<Props, State> {
  state = {
    domains: 0,
  };

  constructor(props: Props) {
    super(props);
    this.getDomains = this.getDomains.bind(this);
  }

  componentDidMount() {
    this.getDomains();
  }

  render() {
    return (
      <div>
        <span className="block text-500 font-medium mb-3">
          Anzahl der Zertifikate
        </span>
        <div className="text-900 font-medium text-xl">{this.state.domains}</div>
      </div>
    );
  }

  async getDomains() {
    let domain = await adminGetCertCount();
    this.setState({ domains: domain[0].Anzahl });
  }
}
