import { Card } from "primereact/card";
import React from "react";
import { SiSepa } from "react-icons/si";
import { FaFileInvoiceDollar } from "react-icons/fa";
import RightChecker from "components/shared/RightChecker";
import InvoiceAdresses from "./InvoiceAdresses";
import PaymentInformation from "./PaymentInformation";

interface Props {
    selectedPayment?: number;
    onAdressSelect?: (kInvoiceAdress: number) => void;
    selectedAdress?: number;
    onPaymentSelect: (kPayment: number) => void;
}

const PaymentOverview = (props: Props) => {
    return (
        <RightChecker right="22">
            <h1 className="text-primary">Zahlungsarten</h1>
            <Card className="surface-100 mb-5 shadow-4">
                <h2>Neue Zahlungsart hinzufügen:</h2>
                <div className="grid justify-content-center">
                    <div className="col-6 justify-content-center">
                        <Card className="flex align-items-center justify-content-center">
                            <PaymentInformation
                                selectedPayment={props.selectedPayment}
                                onPaymentSelect={props.onPaymentSelect}
                            />
                        </Card>
                    </div>
                    <div className="col-6">
                        <Card className="flex align-items-center justify-content-center">
                            <InvoiceAdresses
                                selectedAdress={props.selectedAdress || 0}
                                onAdressSelect={props.onAdressSelect}
                            />
                        </Card>
                    </div>
                </div>
            </Card>
            <Card className="surface-100 shadow-4 justify-content-center">
                <h2>Verfügbare Zahlungsarten:</h2>
                <div className="grid justify-content-center">
                    <div className="col-6 justify-content-center">
                        <Card className="flex align-items-center justify-content-center">
                            <div className="justify-content-center">
                                <SiSepa style={{ fontSize: "100px" }} />
                                <h3>Sepa Lastschrift</h3>
                            </div>
                        </Card>
                    </div>
                    <div className="col-6">
                        <Card className="flex align-items-center justify-content-center">
                            <div className="justify-content-center">
                                <FaFileInvoiceDollar
                                    style={{ fontSize: "100px" }}
                                />
                                <h3>Auf Rechnung</h3>
                            </div>
                        </Card>
                    </div>
                </div>
            </Card>
        </RightChecker>
    );
};

export default PaymentOverview;
