import React, { Component } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
export default class SidemenuItem extends Component {
    static propTypes = {
        to: PropTypes.string,
        title: PropTypes.string,
    };

    variantsItems = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.9,
                y: { stiffness: 1000, velocity: -100 },
            },
        },
        closed: {
            y: 50,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 },
            },
        },
    };

    render() {
        return (
            <motion.li
                variants={this.variantsItems}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="flex"
            >
                <NavLink
                    className="text-light flex align-center"
                    to={this.props.to}
                    onClick={
                        !this.props.isDesktop && this.props.toggleNavHandler
                    }
                >
                    {this.props.children}
                    <span className="nav-title ml-1">{this.props.title}</span>
                </NavLink>
            </motion.li>
        );
    }
}
