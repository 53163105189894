import { saveFeedback } from "logik/apiCalls";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import FeedbackForm from "./FeedbackForm";
import FeedbackWrapper from "./FeedbackWrapper";

interface Props {}
interface State {
    typ: null | "like" | "dislike" | "better" | "bug";
    subjectvalue: string;
    areavalue: string;
}

export default class FeedbackContainer extends Component<Props, State> {
    op: any;
    toast: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            typ: null,
            subjectvalue: "",
            areavalue: "",
        };

        this.getWrapperContent = this.getWrapperContent.bind(this);
        this.getFormValue = this.getFormValue.bind(this);
    }

    render() {
        return (
            <>
                <Toast ref={(el) => (this.toast = el)} />
                <span className="feedback">
                    <Button
                        type="button"
                        icon="pi pi-tag"
                        label="Feedback"
                        onClick={(e) => this.op.toggle(e)}
                        aria-haspopup
                        aria-controls="overlay_panel"
                        className="select-product-button"
                    />

                    <OverlayPanel
                        ref={(el) => (this.op = el)}
                        showCloseIcon
                        id="overlay_panel"
                        style={{
                            width: "auto",
                            right: 0,
                            maxWidth: "400px",
                        }}
                        className="overlaypanel-feedback"
                    >
                        <>
                            {this.state.typ === null && (
                                <FeedbackWrapper
                                    onClick={this.getWrapperContent}
                                />
                            )}
                            {this.state.typ !== null && (
                                <FeedbackForm
                                    typ={this.state.typ}
                                    onClick={this.getWrapperContent}
                                    onSave={this.getFormValue}
                                />
                            )}
                        </>
                    </OverlayPanel>
                </span>
            </>
        );
    }

    async getWrapperContent(typ: null | "like" | "dislike" | "better" | "bug") {
        this.setState({ typ: typ });
    }

    async getFormValue(areavalue: string, subjectvalue: string) {
        var response = await saveFeedback(subjectvalue, areavalue);

        console.log(response);

        if (response.code === 200) {
            this.op.hide();
            this.getWrapperContent(null);
            this.toast.show({
                severity: "success",
                summary: "Feedback wurde gespeichert",
                detail: "Vielen Dank für dein Feedback",
                life: 3000,
            });
        } else {
            this.toast.show({
                severity: "error",
                summary: "Feedback wurde nicht gespeichert",
                detail: "Etwas ist schief gelaufen, bitte versuch es gleich nochmal",
                life: 3000,
            });
        }
    }
}
