import { adminGetCertYearly } from "logik/apiCalls";
import React, { Component } from "react";

type Props = {};

type State = {
  umsatz: number;
};

export default class YearlyCertificate extends Component<Props, State> {
  state = {
    umsatz: 0,
  };

  constructor(props: Props) {
    super(props);
    this.getYearly = this.getYearly.bind(this);
  }

  componentDidMount() {
    this.getYearly();
  }
  render() {
    return (
      <div>
        <span className="block text-500 font-medium mb-3">
          Jährlicher Zertifikats Umsatz
        </span>
        <div className="text-900 font-medium text-xl">
          {Number(this.state.umsatz).toFixed(2)} €
        </div>
      </div>
    );
  }
  async getYearly() {
    let yearly = await adminGetCertYearly();
    this.setState({ umsatz: yearly[0].Anzahl });
  }
}
