import React, { Component } from "react";
import { AiOutlineBulb } from "react-icons/ai";
import { AiFillBug } from "react-icons/ai";

interface Props {
    onClick: (typ: any) => void;
    label:
    | "Mir gefällt"
    | "Mir gefällt nicht"
    | "Verbesserungen"
    | "Bug melden";
    icon: string;
    action: string;
}
interface State { }

export default class FeedbackButton extends Component<Props, State> {
    op: any;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.getIcon = this.getIcon.bind(this);
    }

    render() {
        return (
            <div
                className="col-6 text-center"
                onClick={() => this.props.onClick(this.props.action)}
            >
                {this.getIcon(this.props.icon)}
                <br />
                {this.props.label}
            </div>
        );
    }

    getIcon(icon: string) {
        if (icon === "pi pi-thumbs-up" || icon === "pi pi-thumbs-down") {
            return <i className={icon} style={{ fontSize: "2em" }}></i>;
        } else if (icon === "better") {
            return <AiOutlineBulb style={{ fontSize: "2em" }}></AiOutlineBulb>;
        } else if (icon === "bug") {
            return <AiFillBug style={{ fontSize: "2em" }}></AiFillBug>;
        }
    }
}
