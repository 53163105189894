import React, { Component } from "react";
import FeedbackButton from "./FeedbackButton";

interface Props {
    onClick: (typ: any) => void;
}
interface State { }

export default class FeedbackWrapper extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="grid">
                    <FeedbackButton
                        onClick={this.props.onClick}
                        label="Mir gefällt"
                        icon="pi pi-thumbs-up"
                        action="like"
                    />
                    <FeedbackButton
                        onClick={this.props.onClick}
                        label="Mir gefällt nicht"
                        icon="pi pi-thumbs-down"
                        action="dislike"
                    />
                    <FeedbackButton
                        onClick={this.props.onClick}
                        label="Verbesserungen"
                        icon="better"
                        action="better"
                    />
                    <FeedbackButton
                        onClick={this.props.onClick}
                        label="Bug melden"
                        icon="bug"
                        action="bug"
                    />
                </div>
            </div>
        );
    }
}
