import axios from "axios";
export const nodeAxios = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_DOMAIN_NODE,
});

export const assignNodes = async () => {
    let res = await nodeAxios.get("/maintenance/assignNodes");
    console.log("nodes aktualisiert");
};

// export const adminShowAbos = async () => {
//     let res = await axios.post("administration/manuelleEinpflegung/showAbos.php", {
//     });
//     return res.data
// };

export const proxGetServerList = async (pool: string) => {
    var serverlist: any[] = [];
    await nodeAxios
        .get(`server/getServerList/` + pool)
        .then((res) => {
            if (res.data.length > 0) {
                serverlist = Object.values(res.data);
            }
        });
    return serverlist.sort((a, b) => (a.vmid > b.vmid ? 1 : -1));
};

export const proxGetVmInfo = async (nodename: string, vmid: any) => {
    const res = await nodeAxios.get("server/getVmInfo/" + vmid);
    return res;
};

export const proxGetTarife = async () => {
    const res = await axios.post("server/tarife/getTarife.php");
    return res.data;
};

export const apiCancelAbo = async (cDescription: string, dErstellt: Date, kcPoolName: string) => {
    const res = await axios.post("abos/delete", {
        cDescription,
        dErstellt,
        kcPoolName
    });
    return res.data;
};

export const apiCancelCerts = async (nId: number, cCertDomain: string, dErstellt: Date) => {
    const res = await axios.post("certs/delete", {
        nId,
        cCertDomain,
        dErstellt
    } );
    return res.data;
};

export const apiCancelTarif = async (nName: number, cDate: Date) => {
    const res = await axios.post("tarifs/delete", {
        nName,
        cDate,
    } );
    return res.data;
};