import React, { Component } from "react";
import { motion } from "framer-motion";
import { BiServer } from "react-icons/bi";
import { GoDashboard } from "react-icons/go";
import { FiPlusCircle } from "react-icons/fi";
import { VscHistory } from "react-icons/vsc";
import { FaShieldAlt } from "react-icons/fa";
import { RiShoppingCartLine } from "react-icons/ri";
import { CgServer } from "react-icons/cg";
import { FiServer } from "react-icons/fi";
import "./layout.css";
import SidemenuItem from "./SidemenuItem";
import RightChecker from "components/shared/RightChecker";
import { Badge } from "primereact/badge";
import { FaFileInvoiceDollar } from "react-icons/fa";

export default class Sidemenu extends Component {
  render() {
    const variants = {
      open: {
        transition: { staggerChildren: 0.1, delayChildren: 0.08 },
      },
      closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    };

    const variantsDesktop = {
      open: { opacity: 1, x: 0, display: "block" },
      closed: { opacity: 0, x: "-100%", display: "none" },
    };
    const variantesMobile = {
      open: { opacity: 1, y: 0, display: "block" },
      closed: { opacity: 0, y: "-100%", display: "none" },
    };
    return (
      <motion.div
        id="sidebar"
        className="bg-gradient-primary sidebar"
        animate={this.props.navopen ? "open" : "closed"}
        variants={this.props.isDesktop ? variantsDesktop : variantesMobile}
      >
        {!this.props.isDesktop && (
          <>
            <i
              className="pi pi-times close"
              onClick={!this.props.isDesktop && this.props.toggleNavHandler}
            ></i>
            <h4 className="page-header p-text-center">Menu</h4>
          </>
        )}

        {this.props.isDesktop && (
          <>
            <img
              className="img-fluid p-d-block p-m-auto mb-3"
              alt="logo"
              src="img/logo_weiss_svg.svg"
              style={{ maxWidth: "85%" }}
            />
            <hr className="sidebar-divider my-1" />
          </>
        )}
        <ul className="sidebarSection">
          <SidemenuItem title="Dashboard" to="/dashboard">
            <GoDashboard />
          </SidemenuItem>
        </ul>
        <hr className="sidebar-divider" />
        <div className="sidebar-heading flex align-center mb-1 mt-2">
          <BiServer />
          <span className="ml-1">QCloud</span>
        </div>

        <motion.ul variants={variants} className="sidebarSection">
          <RightChecker right="4">
            <SidemenuItem title="Serverdashboard" to="/server">
              <FiServer />
            </SidemenuItem>
          </RightChecker>
          <RightChecker right="3">
            <SidemenuItem title="Neuen Server erstellen" to="/newserver">
              <FiPlusCircle />
            </SidemenuItem>
          </RightChecker>
          <RightChecker right="10000">
            <SidemenuItem title="Kosten Gesamt" to="/chronic">
              <VscHistory /> <Badge value={"WIP"} severity={"info"} />
            </SidemenuItem>
          </RightChecker>
          <RightChecker right="20">
            <SidemenuItem title="Domain Registrieren" to="/registerDomain">
              <CgServer />
            </SidemenuItem>
          </RightChecker>
          <RightChecker right="12">
            <SidemenuItem title="Rechte-Management" to="/rightmanagement">
              <FaShieldAlt />
            </SidemenuItem>
          </RightChecker>
          <RightChecker right="22">
            <SidemenuItem title="Bezahlinformationen" to="/bezahlinformationen">
              <FaFileInvoiceDollar />
            </SidemenuItem>
          </RightChecker>
        </motion.ul>

        <RightChecker right="10000">
          <hr className="sidebar-divider mt-2" />
          <div className="sidebar-heading flex align-center mb-1 mt-2">
            <BiServer />
            <span className="ml-1">Admin</span>
          </div>
          <motion.ul variants={variants} className="sidebarSection">
            <SidemenuItem title="Admin Tarif Management" to="/adminTarife">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="Hallo" to="/hallo">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="Einkaufsliste" to="/shoppinglist">
              <RiShoppingCartLine />
            </SidemenuItem>
            <SidemenuItem title="Kamp" to="/kamp">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="User-All-in-One" to="/fullUserData">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="Domain-Übersicht" to="/ShowAbo">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="Zertifikat-Übersicht" to="/ShowCertificates">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="User Tarif-Übersicht" to="/ShowUserTariff">
              <CgServer />
            </SidemenuItem>
          </motion.ul>
        </RightChecker>
        <hr className="sidebar-divider" />

        <RightChecker right="10000">
          <hr className="sidebar-divider mt-2" />
          <div className="sidebar-heading flex align-center mb-1 mt-2">
            <BiServer />
            <span className="ml-1">Migration</span>
          </div>
          <motion.ul variants={variants} className="sidebarSection">
            <SidemenuItem title="User hinzufügen" to="/adminAddUser">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="Tarif hinzufügen" to="/AddTariff">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="Domain hinzufügen" to="/adminAddDomain">
              <CgServer />
            </SidemenuItem>
            <SidemenuItem title="Zertifikat hinzufügen" to="/addCertificate">
              <CgServer />
            </SidemenuItem>
          </motion.ul>
        </RightChecker>
      </motion.div>
    );
  }
}
