import { UserContext } from "App";
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ children, ...rest }) => {
  const user = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.user &&
          user.user.multiFactor.enrolledFactors.length > 0 ? (
          children
        ) : (
          <>
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          </>
        )
      }
    />
  );
};
