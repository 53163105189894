import { apiCancelCerts } from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { CertInterface } from "./ShowCertificates";

type Props = {
  cert: CertInterface;
};

type State = {
  displayBasic: boolean;
};

export default class DeleteButtonCerts extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayBasic: false,
    };
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
    this.kuendige = this.kuendige.bind(this);
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Button
          className="deleteButton"
          label="Kündigen"
          aria-label="Kündigen"
          onClick={() => this.onClick("displayBasic")}
        />
        <Dialog
          header="Bestätigung"
          visible={this.state.displayBasic}
          style={{ width: "20vw" }}
          footer={this.renderFooter("displayBasic")}
          onHide={() => this.onHide("displayBasic")}
        >
          <p>Diesen Eintrag zum Ende der Vertragslaufzeit kündigen?</p>
        </Dialog>
      </>
    );
  }

  onClick(name: any) {
    this.setState({
      displayBasic: true,
    });
  }

  onHide(name: string) {
    this.setState({
      displayBasic: false,
    });
  }
  renderFooter(name: any) {
    return (
      <div>
        <Button
          label="Nein"
          icon="pi pi-times"
          onClick={() => this.onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          onClick={() => {
            this.onHide(name),
              this.kuendige(
                this.props.cert.nId,
                this.props.cert.cCertDomain,
                this.props.cert.dErstellt
              );
          }}
          autoFocus
        />
      </div>
    );
  }

  async kuendige(nId: number, cCertDomain: string, dErstellt: Date) {
    apiCancelCerts(nId, cCertDomain, dErstellt);
  }
}
