import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { Component } from "react";

interface Props {
    typ: null | "like" | "dislike" | "better" | "bug";
    onClick: (typ: any) => void;
    onSave: (areavalue: string, subjectvalue: string) => void;
}
interface State {
    subjectvalue: string;
    areavalue: string;
}

export default class FeedbackForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            subjectvalue: "",
            areavalue: "",
        };
    }

    render() {
        return (
            <div className="grid feedbackformgrid">
                <div className="col-12">Feedback</div>

                <div className="col-12 text-center">
                    <span className="p-input-icon-left">
                        <i className="pi pi-envelope" />
                        <InputText
                            value={this.state.subjectvalue}
                            placeholder={(() => {
                                if (this.props.typ === "like") {
                                    return "Mir gefällt folgendes";
                                } else if (this.props.typ === "dislike") {
                                    return "Mir gefällt folgendes nicht";
                                } else if (this.props.typ === "better") {
                                    return "Bitte macht folgendes Besser";
                                } else if (this.props.typ === "bug") {
                                    return "Mir ist folgender Bug aufgefallen";
                                }
                            })()}
                            onChange={(e) =>
                                this.setState({ subjectvalue: e.target.value })
                            }
                        />
                    </span>
                </div>
                <div className="col-12 text-center">
                    <InputTextarea
                        rows={5}
                        value={this.state.areavalue}
                        onChange={(e) =>
                            this.setState({ areavalue: e.target.value })
                        }
                    />
                </div>
                <div className="col-6 text-center">
                    <Button
                        label="Zurück"
                        icon="pi pi-arrow-left"
                        onClick={() => this.props.onClick(null)}
                    />
                </div>
                <div className="col-6 text-center">
                    <Button
                        label="Speichern"
                        onClick={() =>
                            this.props.onSave(
                                this.state.areavalue,
                                this.state.subjectvalue
                            )
                        }
                        icon="pi pi-check"
                        disabled={
                            this.state.subjectvalue.trim() === "" ||
                            this.state.areavalue.trim() === ""
                        }
                    />
                </div>
            </div>
        );
    }
}
