import axios from "axios";

export const rightsHasRight = async (right, pool) => {
    const response = await axios.post("general/rights/hasRights.php", {
        right: right,
        pool: pool,
    });

    return response.data === 1;
};

export const rightsGetUserSettings = async (right) => {
    console.log(axios.defaults.headers.Auth);
    const response = await axios.post("general/settings/getSettings.php");
    console.log(response.data);
    return response.data;
};

export const rightsGetAllRights = async () => {
    const response = await axios.post("general/rights/getAllRights.php");
    return response.data;
};

export const rightsAssignRight = async (right, rightValue, pool) => {
    const response = await axios.post("general/rights/assignRights.php", {
        right: right,
        rightValue: rightValue,
        pool: pool,
    });
    console.log(response);
    return response.data;
};
