import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { adminPoolList, adminShowAbos } from "logik/apiCalls";
import { Tag } from "primereact/tag";
import { MultiSelect } from "primereact/multiselect";
import DomainCounter from "./DomainCounter";
import TLDCounter from "./TLDCounter";
import YearlyCounter from "./YearlyCounter";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import DeleteButton from "./DeleteButton";

export interface AboInterface {
  cDescription: string;
  dErstellt: Date;
  fPreisProAbrechnugszeitraum: string;
  nAbrechnungszeitraumInTagen: string;
  dAboBeendetAm: Date;
  cType: string;
  kcPoolName: string;
  KDN: string;
  cFirma: string;
}

export interface PoolInterface {
  kcPoolName: string;
}

type Props = {};

type State = {
  abos: AboInterface[];
  pools: PoolInterface[];
  // globalFilterValue1: string;
  //   filters1: null,
};

export default class ShowDomains extends Component<Props, State> {
  state = {
    abos: [],
    pools: [{ kcPoolName: "moso" }],
    // globalFilterValue1: "",
    // filters1: null
  };

  constructor(props: Props) {
    super(props);

    this.showDomainList = this.showDomainList.bind(this);
    this.poolList = this.poolList.bind(this);
  }

  componentDidMount() {
    this.showDomainList();
  }

  // onGlobalFilterChange1(e: []) {
  //   const value = e.target.value;
  //   let filters1 = {this.state.filters1};
  //   filters1["global"].value = value;

  //   this.setState({ filters1, globalFilterValue1: value });
  // }

  render() {
    return (
      <div>
        <div className="grid ml-5 mr-6">
          <div className="col-12 md:col-6 lg:col-4">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <DomainCounter></DomainCounter>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">24 new </span>
              <span className="text-500">since last visit</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <YearlyCounter></YearlyCounter>
                <div
                  className="flex align-items-center justify-content-center bg-orange-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">%52+ </span>
              <span className="text-500">since last week</span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
              <div className="flex justify-content-between mb-3">
                <TLDCounter></TLDCounter>
                <div
                  className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-inbox text-cyan-500 text-xl"></i>
                </div>
              </div>
              <span className="text-green-500 font-medium">520 </span>
              <span className="text-500">newly registered</span>
            </div>
          </div>
        </div>
        <div className="card">
          {/* <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={this.state.globalFilterValue1}
              onChange={this.onGlobalFilterChange1}
              placeholder="Keyword Search"
            />
          </span> */}
          <div className="ml-5 mr-6">
            <DataTable
              value={this.state.abos}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              filterDisplay="row"
            >
              <Column
                field="cDescription"
                header="Beschreibung"
                sortable
                showFilterMatchModes={true}
                filter
              ></Column>
              <Column field="cId" header="Handle" sortable filter></Column>
              <Column
                field="dErstellt"
                header="Erstellt am"
                sortable
                filter
                body={(rowdata: AboInterface) => (
                  <Tag
                    severity="info"
                    value={
                      rowdata.dErstellt.toString().substring(8, 10) +
                      "/" +
                      rowdata.dErstellt.toString().substring(5, 7) +
                      "/" +
                      rowdata.dErstellt.toString().substring(0, 4)
                    }
                  ></Tag>
                )}
              ></Column>
              <Column
                field="fPreisProAbrechnugszeitraum"
                header="Preis Pro Zeitraum"
                sortable
                filter
                body={(rowdata: AboInterface) => (
                  <Tag value={rowdata.fPreisProAbrechnugszeitraum + " €"}></Tag>
                )}
              ></Column>
              <Column
                field="nAbrechnungszeitraumInTagen"
                header="Abrechnungszeitraum"
                sortable
                filter
              ></Column>
              <Column
                field="dAboBeendetAm"
                header="Gekündigt am"
                sortable
                filter
                body={(rowdata: AboInterface) => (
                  <Tag
                    severity="info"
                    value={
                      rowdata.dAboBeendetAm !== null
                        ? rowdata.dAboBeendetAm.toString().substring(8, 10) +
                          "/" +
                          rowdata.dAboBeendetAm.toString().substring(5, 7) +
                          "/" +
                          rowdata.dAboBeendetAm.toString().substring(0, 4)
                        : "Fortlaufend"
                    }
                  ></Tag>
                )}
              ></Column>
              <Column
                field="cType"
                header="Typ"
                sortable
                filter
                body={(rowdata: AboInterface) => (
                  <Tag value={rowdata.cType}></Tag>
                )}
              ></Column>
              <Column
                field="kcPoolName"
                header="Pool Name"
                sortable
                //filterField="pools"
                showFilterMatchModes={true}
                filter

                //  filterElement={this.representativeFilterTemplate}
              ></Column>

              <Column
                field="KDN"
                header="KDN"
                sortable
                filter
                body={(rowdata: AboInterface) => (
                  <Button
                    className="p-button-text"
                    label={"" + rowdata.KDN}
                    tooltip={rowdata.cFirma}
                  ></Button>
                )}
              ></Column>
              <Column
                body={(rowdata: AboInterface) => (
                  <DeleteButton abo={rowdata}></DeleteButton>
                )}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    );
  }

  async showDomainList() {
    let abos = await adminShowAbos();
    this.setState({ abos: abos });
    console.log(abos);
  }

  async poolList() {
    let pools = await adminPoolList();
    this.setState({ pools: pools });
  }
}
