import { getInvoiceAdresses } from "logik/apiCalls";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import React, { Component } from "react";
import NewInvoiceAdresses from "./NewInvoiceAdresses";

interface Props {
    selectedAdress?: number;
    onAdressSelect?: (payment: number) => void;
}
interface State {
    showNewAdress: boolean;
    adresses: InvoiceAdress[];
}

export interface InvoiceAdress {
    kInvoiceAdresse: number;
    cFirma: string;
    cFirstname: string;
    cLastname: string;
    cStreet: string;
    cHousenr: string;
    cCity: string;
    cPLZ: string;
    cTel: string;
    cMail: string;
}

export default class InvoiceAdresses extends Component<Props, State> {
    state = {
        adresses: [],
        showNewAdress: false,
    };

    static defaultProps = {
        selectable: false,
    };

    constructor(props: Props) {
        super(props);
        this.getAdresses = this.getAdresses.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        this.getAdresses();
    }

    render() {
        return (
            <div>
                <h2>Vorhandene Adressen:</h2>
                <p>
                    {this.state.adresses.length === 0 ? (
                        <strong>Keine Adressen vorhanden</strong>
                    ) : (
                        <ul>
                            {this.state.adresses.map(
                                (adress: InvoiceAdress) => (
                                    <li key={adress.kInvoiceAdresse}>
                                        {this.props.onAdressSelect && (
                                            <RadioButton
                                                inputId="city2"
                                                name="city"
                                                value={adress.kInvoiceAdresse}
                                                onChange={(e) => {
                                                    if (
                                                        this.props
                                                            .onAdressSelect
                                                    ) {
                                                        this.props.onAdressSelect(
                                                            e.value
                                                        );
                                                    }
                                                }}
                                                checked={
                                                    this.props
                                                        .selectedAdress ===
                                                    adress.kInvoiceAdresse
                                                }
                                            />
                                        )}
                                        {adress.cFirma}
                                        <br />
                                        {adress.cFirstname} {adress.cLastname}
                                        <br />
                                        {adress.cStreet} {adress.cHousenr}
                                        <br />
                                        {adress.cCity} {adress.cPLZ}
                                        <br />
                                        {adress.cTel}
                                        <br />
                                        {adress.cMail}
                                        <br />
                                    </li>
                                )
                            )}
                        </ul>
                    )}
                </p>
                <Button
                    label="Neue Adresse anlegen"
                    onClick={() => this.setState({ showNewAdress: true })}
                />
                <Dialog
                    style={{ width: "50vw" }}
                    onHide={() => this.setState({ showNewAdress: false })}
                    visible={this.state.showNewAdress}
                    header="Neue Adresse anlegen"
                >
                    <NewInvoiceAdresses onHide={this.onHide} />
                </Dialog>
            </div>
        );
    }

    onHide() {
        this.setState({ showNewAdress: false });
        this.getAdresses();
    }

    async getAdresses() {
        var adresses: InvoiceAdress[] = await getInvoiceAdresses();
        console.log(adresses);
        this.setState({ adresses: adresses });
    }
}
