import { adminGetTLD } from "logik/apiCalls";
import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";

export interface TLDInterface {
  cTLD: string;
  Anzahl: number;
}
type Props = {};

type State = {
  tlds: TLDInterface[];
  heads: string[];
  val: number[];
};

export default class DomainCounter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getTLD = this.getTLD.bind(this);

    this.state = {
      tlds: [],
      heads: [],
      val: [],
    };
  }

  componentDidMount() {
    this.getTLD();
  }

  render() {
    return (
      <div className="card flex justify-content-center">
        <div className="ml-8">
          <Chart
            type="doughnut"
            data={{
              labels: this.state.heads,
              datasets: [
                {
                  data: this.state.val,
                  backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#433caa",
                    "#0c8059",
                    "#1c413e",
                    "#c657ca",
                    "#83ac85",
                    "#79665f",
                    "#6fc7b8",
                    "#5e725c",
                  ],
                  hoverBackgroundColor: ["#c63f1b"],
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    color: "#495057",
                  },
                },
              },
            }}
            style={{ position: "relative", width: "100%" }}
          />{" "}
        </div>
      </div>
      // <div>
      //   <span className="block text-500 font-medium mb-3">Anzahl pro TLD</span>
      //   <div className="text-900 font-medium text-xl">
      //     <DataTable
      //       value={this.state.tlds}
      //       responsiveLayout="scroll"
      //       size="small"
      //       paginator
      //       rows={2}
      //     >
      //       <Column field="cTLD" header="TLD"></Column>
      //       <Column field="Anzahl" header="Anzahl"></Column>
      //     </DataTable>
      //   </div>
      // </div>
    );
  }
  async getTLD() {
    let tld = await adminGetTLD();
    this.setState({ tlds: tld });
    this.state.tlds.forEach((element) =>
      this.setState({ heads: this.state.heads.concat(element.cTLD) })
    );
    this.state.tlds.forEach((element) =>
      this.setState({ val: this.state.val.concat(element.Anzahl) })
    );
    console.log(this.state.heads);
    console.log(this.state.val);
  }
}
