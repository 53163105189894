import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import UserAutocomplete from "./UserAutocomplete";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { adminAddDomain } from "logik/apiCalls";
import { InputMask } from "primereact/inputmask";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

type Props = {};

type State = {
  domain: string;
  handle: string;
  datum: any;
  user: string;
  price: number;
  label: string;
  typed: string;
  tld: string;
};

export default class AddDomain extends Component<Props, State> {
  domains = [
    { label: "Inklusivdomains", code: "Inklusivdomains" },
    { label: "Zusatzdomain", code: "Zusatzdomain" },
    { label: "Eigenebedarf", code: "Eigenbedarf" },
  ];

  constructor(props: Props) {
    super(props);
    this.saveDomain = this.saveDomain.bind(this);
    this.onLoadingClick1 = this.onLoadingClick1.bind(this);

    this.state = {
      domain: "",
      handle: "",
      datum:
        DateTime.now().toString().substring(8, 10) +
        "/" +
        DateTime.now().toString().substring(5, 7) +
        "/" +
        DateTime.now().toString().substring(0, 4),
      user: "",
      price: 0,
      label: "Absenden",
      typed: "",
      tld: "",
    };
  }

  onLoadingClick1() {
    this.setState({ label: "Versendet!" });
    setTimeout(() => {
      this.setState({ domain: "" });
      this.setState({ handle: "" });
      this.setState({ datum: "" });
      this.setState({ user: "" });
      this.setState({ price: 0 });
      this.setState({ tld: "" });
      this.setState({ label: "Absenden" });
      this.setState({ typed: "" });
    }, 1000);
  }

  render() {
    return (
      <>
        <div className="ml-3 pl-3">
          <h1>Domain an Pooluser binden</h1>
          <span className="pr-2">Domainnamen angeben:</span>
          <InputText className="mb-3"
            value={this.state.domain}
            onChange={(e) => this.setState({ domain: e.target.value })}
            placeholder="Domainname"
          />
          <br />
          <span className="pr-8">TLD angeben:</span>
          <InputText className="mb-3"
            value={this.state.tld}
            onChange={(e) => this.setState({ tld: e.target.value })}
            placeholder="TLD"
          />
          <br />
          <span className="pr-7">Handle angeben:</span>
          <InputText className="mb-3"
            value={this.state.handle}
            onChange={(e) => this.setState({ handle: e.target.value })}
            placeholder="Handle"
          />
          <br />
          <span className="pr-7">Datum angeben:</span>
          <Calendar className="mb-3"
            dateFormat="dd/mm/yy"
            value={this.state.datum}
            placeholder={this.state.datum}
            onChange={(e) => this.setState({ datum: e.value })}
          />
          <br />
          <span className="pr-8">Preis angeben:</span>
          <InputNumber className="mb-3"
            value={this.state.price}
            onValueChange={(e) => this.setState({ price: e.target.value })}
            placeholder="Preis"
            mode="currency"
            currency="EUR"
            locale="de-DE"
            minFractionDigits={2}
          />
          <br />
          <span className="pr-8">Domain-Typ wählen</span>
          <Dropdown
            className="mt-2"
            value={this.state.typed}
            options={this.domains}
            onChange={(e) => this.setState({ typed: e.value.label })}
            optionLabel="label"
            placeholder={this.state.typed}
          />
          <br />
          <div>
            <span>User angeben:</span>
            <UserAutocomplete
              onSelect={(user: string) => this.setState({ user: user })}
            />
            <div className="pt-2 ">
              <Button className="mb-3"
                label={this.state.label}
                onClick={this.saveDomain}
                disabled={
                  this.state.user === "" ||
                  this.state.handle === "" ||
                  this.state.datum === "" ||
                  this.state.price === null ||
                  this.state.domain === "" ||
                  this.state.tld === "" ||
                  this.state.typed === ""
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  saveDomain() {
    this.onLoadingClick1();
    adminAddDomain(
      this.state.domain,
      this.state.handle,
      this.state.datum,
      this.state.price,
      this.state.user,
      this.state.typed,
      this.state.tld
    );
  }
}
