import { saveInvoiceAdress } from "logik/apiCalls";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

import React, { Component } from "react";

interface Props {
    onHide: () => void;
}
interface State {
    firma: string;
    vorname: string;
    name: string;
    strasse: string;
    postleitzahl: string;
    stadt: string;
    land: string;
    telefon: string;
    telefax: string;
    email: string;
}

export default class NewInvoiceAdresses extends Component<Props, State> {
    state = {
        firma: "",
        vorname: "",
        name: "",
        strasse: "",
        postleitzahl: "",
        stadt: "",
        land: "",
        telefon: "",
        telefax: "",
        email: "",
    };

    constructor(props: Props) {
        super(props);

        this.saveInvoiceAdress = this.saveInvoiceAdress.bind(this);
    }

    render() {
        return (
            <div className="formgrid grid">
                <div className="field col-12">
                    <label htmlFor="address">Firma</label>
                    <InputText
                        className=" w-full"
                        value={this.state.firma}
                        onChange={(e) =>
                            this.setState({
                                firma: e.target.value,
                            })
                        }
                        type="text"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="firstname6">Vorname</label>
                    <InputText
                        className=" w-full"
                        value={this.state.vorname}
                        onChange={(e) =>
                            this.setState({
                                vorname: e.target.value,
                            })
                        }
                        id="firstname6"
                        type="text"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="lastname6">Lastname</label>
                    <InputText
                        className=" w-full"
                        value={this.state.name}
                        onChange={(e) =>
                            this.setState({
                                name: e.target.value,
                            })
                        }
                        id="lastname6"
                        type="text"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="address">Strasse und Hausnummer</label>
                    <InputText
                        className=" w-full"
                        value={this.state.strasse}
                        onChange={(e) =>
                            this.setState({
                                strasse: e.target.value,
                            })
                        }
                        type="text"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="address">Telefon</label>
                    <InputMask
                        className=" w-full"
                        value={this.state.telefon}
                        onChange={(e) =>
                            this.setState({
                                telefon: e.value,
                            })
                        }
                        mask="+49 999 ?999999999999999"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="state">Land</label>
                    <Dropdown
                        className=" w-full"
                        value={this.state.land}
                        options={["Germany", "Switzerland", "Austria"]}
                        onChange={(e) => this.setState({ land: e.value })}
                        placeholder="Select"
                    />
                </div>
                <div className="field col-12 md:col-3">
                    <label htmlFor="city">City</label>
                    <InputText
                        className=" w-full"
                        value={this.state.stadt}
                        onChange={(e) =>
                            this.setState({
                                stadt: e.target.value,
                            })
                        }
                        id="city"
                        type="text"
                    />
                </div>
                <div className="field col-12 md:col-3">
                    <label htmlFor="zip">PLZ</label>
                    <InputText className=" w-full" id="zip" type="text" />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="zip">Email</label>
                    <InputText
                        className=" w-full"
                        value={this.state.email}
                        placeholder=""
                        type="email"
                        onChange={(e) =>
                            this.setState({
                                email: e.target.value,
                            })
                        }
                    ></InputText>
                </div>
                <Button label="Speichern" onClick={this.saveInvoiceAdress} />
            </div>
        );
    }

    saveInvoiceAdress = async () => {
        saveInvoiceAdress(
            this.state.firma,
            this.state.vorname,
            this.state.name,
            this.state.strasse,
            this.state.postleitzahl,
            this.state.stadt,
            this.state.land,
            this.state.telefon,
            this.state.email
        );
        this.props.onHide();
    };
}
